.mail-form {
    input:focus, textarea:focus {
        outline: none;
    }
    input {
        color: $red;
        background-color: initial;
        align-items: center;
        padding: 15px;
        border: 2px solid $red;
        font-weight: 400;
        font-size: 0.8rem;
    }
    input[type='submit'] {
        &:hover,&:focus-visible {
            cursor: pointer;
            border-width: 3px;
            padding: 14px;
            border-color: $red;
        }
    }
}

.mail-form-input {
    width: 75%;
    &:hover,&:focus-visible {
        border-width: 3px;
        padding: 14px;
        border-color: $red;
    }
}